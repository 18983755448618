<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ tituloFormulario }}
      </div>
    </div>
    <v-form ref="form" class="row">
      <input-select
        v-model="form.tipoCadastro"
        :options="opcoes.tipoCadastro"
        class="col-12 col-md-3"
        :label="$t('modulos.solicitacao_cadastro.formulario.tipo_cadastro')"
        obrigatorio
        :disabled="disabledId"
        :loading="loadingTipoCadastro"
      />
      <input-usuarios
        v-if="!disabledId"
        v-model="form.usuarioSolicitante"
        class="col-12 col-md-3"
        :label="
          $t('modulos.solicitacao_cadastro.formulario.usuario_solicitante')
        "
        :disabled="disabledId"
        obrigatorio
        :multiplos="false"
      />
      <input-text
        v-else
        v-model="form.usuarioSolicitante"
        class="col-12 col-md-3"
        :label="
          $t('modulos.solicitacao_cadastro.formulario.usuario_solicitante')
        "
        disabled
        obrigatorio
      />
      <input-select
        v-if="buscarPermissao('SolicitacaoCadastro', 'AlterarSituacao')"
        v-model="form.status"
        :options="opcoes.status"
        class="col-12 col-md-3"
        :label="$t('modulos.solicitacao_cadastro.formulario.situacao')"
        :disabled="disabledStatus"
        obrigatorio
        :loading="loadingStatus"
      />
      <input-date
        v-model="form.dataSolicitacao"
        class="col-12 col-md-3"
        :label="$t('modulos.solicitacao_cadastro.formulario.data_solicitacao')"
        disabled
        obrigatorio
      />
      <input-textarea
        v-model="form.texto"
        class="col-12"
        :label="$t('modulos.solicitacao_cadastro.formulario.texto')"
        obrigatorio
        :disabled="disabledGeral"
      />
      <input-textarea
        v-model="form.observacao"
        class="col-12"
        :label="$t('modulos.solicitacao_cadastro.formulario.observacao')"
        :disabled="disabledGeral"
      />
      <input-usuarios
        v-if="!disabilitadoResolucao"
        v-model="form.usuarioResolucao"
        class="col-12 col-md-8"
        :label="
          $t(
            'modulos.solicitacao_cadastro.formulario.usuario_responsavel_resolucao'
          )
        "
        :disabled="disabilitadoResolucao"
        :obrigatorio="resolucaoAtivo"
        :multiplos="false"
      />
      <input-text
        v-else
        v-model="form.usuarioResolucao"
        class="col-12 col-md-8"
        :label="
          $t(
            'modulos.solicitacao_cadastro.formulario.usuario_responsavel_resolucao'
          )
        "
        :disabled="disabilitadoResolucao"
        :obrigatorio="resolucaoAtivo"
      />
      <input-date
        v-model="form.dataResolucao"
        class="col-12 col-md-4"
        :label="$t('modulos.solicitacao_cadastro.formulario.data_resolucao')"
        :disabled="disabilitadoResolucao"
        :obrigatorio="resolucaoAtivo"
      />
      <input-textarea
        v-model="form.descricaoResolucao"
        class="col-12"
        :label="
          $t('modulos.solicitacao_cadastro.formulario.descricao_resolucao')
        "
        :obrigatorio="resolucaoAtivo"
        :disabled="disabilitadoResolucao"
      />
    </v-form>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="cancelar">
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao :disabled="disabledSalvar" @click="confirmacaoSalvar">
        <v-icon>$mdiContentSaveOutline</v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import SolicitacaoCadastroService from '@common/services/cadastros/SolicitacaoCadastroService';
import { SolicitacaoCadastroModel } from '@common/models/cadastros/SolicitacaoCadastroModel';
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
import { InputUsuarios } from '@components/inputs';
import { DropdownModel } from '@common/models/geral/DropdownModel';
import helpers from '@common/utils/helpers';

export default {
  components: {
    InputUsuarios,
  },
  props: ['id'],
  data() {
    return {
      valido: false,
      disabledStatus: false,
      disabledGeral: false,
      disabledSalvar: false,
      disabilitadoResolucao: false,
      resolucaoAtivo: false,
      form: new SolicitacaoCadastroModel({}),
      loadingTipoCadastro: false,
      loadingStatus: false,
      disabledId: false,
      opcoes: {
        tipoCadastro: [],
        status: [],
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id)
        return this.$t('modulos.solicitacao_cadastro.titulos.editar');
      return this.$t('modulos.solicitacao_cadastro.titulos.novo');
    },
  },
  watch: {
    'form.status': {
      handler() {
        if (this.form.status === 'Encerrado' && !this.form.usuarioResolucao) {
          this.form.usuarioResolucao = new DropdownModel(
            this.$store.getters['Autenticacao/usuario']
          );
          this.form.dataResolucao = new Date();
          this.disabilitadoResolucao = false;
          this.resolucaoAtivo = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.buscarTipoCadastro();
    this.buscarStatus();
    if (!this.id) this.iniciarForm();
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'SolicitacaoCadastro', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'SolicitacaoCadastro', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    iniciarForm: function () {
      this.disabledStatus = true;
      this.disabilitadoResolucao = true;
      this.form.usuarioSolicitante = new DropdownModel(
        this.$store.getters['Autenticacao/usuario']
      );
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      SolicitacaoCadastroService.buscar(this.id)
        .then((res) => {
          this.form = new SolicitacaoCadastroModel(res.data);
          this.valido = true;
          this.formatarFormEditar();
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.solicitacao_cadastro.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    formatarFormEditar: function () {
      this.disabledId = true;
      this.disabilitadoResolucao = true;
      this.form.usuarioSolicitante = this.form.usuarioSolicitante?.nome;
      if (this.form.usuarioResolucao)
        this.form.usuarioResolucao = this.form.usuarioResolucao?.nome;

      if (this.form.status == 'Encerrado') {
        this.disabledGeral = true;
        this.disabledStatus = true;
        this.disabledSalvar = true;
      }
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    salvar: function () {
      if (this.$refs.form) this.valido = this.$refs.form.validate();
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      SolicitacaoCadastroService.salvar(this.form)
        .then(() => {
          this.$router.push({ name: 'solicitacao-cadastro' });
          this.toastSucesso(
            this.$t(`modulos.solicitacao_cadastro.cadastro_sucesso`)
          );
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'solicitacao-cadastro' });
      });
    },
    buscarTipoCadastro: function () {
      this.loadingTipoCadastro = true;
      EnumeradorService.buscar('EnumTipoCadastro')
        .then((res) => {
          res.sort(helpers.ordenarDropdown);
          this.opcoes.tipoCadastro = res;
        })
        .finally(() => {
          this.loadingTipoCadastro = false;
        });
    },
    buscarStatus: function () {
      this.loadingStatus = true;
      EnumeradorService.buscar('EnumStatus')
        .then((res) => {
          this.opcoes.status = res;
          if (!this.id) this.form.status = this.opcoes.status[0];
        })
        .finally(() => {
          this.loadingStatus = false;
        });
    },
  },
};
</script>
